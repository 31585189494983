import { Layout } from 'antd';
import Sider from '../../containers/sider/index';
import { mobileAndTabletcheck } from '@arivaa-react/helpers/web';
import Link from '@arivaa-react/components/link';
import { renderRoutes } from 'react-router-config';
import './styles.scss';
import Header from '../../containers/header';
import { useDrawer } from '../../hooks/useDrawer';
import { useHorizontalMenu } from '../../hooks/useHorizontalMenu';
import { getCurrentRoute } from '@arivaa-react/react-router5';

const Page = ({ route }) => {
  const { toggleDrawer, drawer } = useDrawer();
  const { horizontalMenu } = useHorizontalMenu();
  const currentRoute = getCurrentRoute();
  const { header } = route;
  return (
    <div className="home">
      <Layout style={{ minHeight: '100vh' }}>
        {drawer &&
        mobileAndTabletcheck() &&
        currentRoute.routeKey !== 'home.diagram' ? (
          <Link className="overlay" onClick={() => toggleDrawer(drawer)} />
        ) : null}
        {!horizontalMenu ? <Sider route={route} /> : null}
        <div className="layout-content">
          {header !== false ? <Header type={header} /> : null}
          <div className="home-layout">{renderRoutes(route.routes)}</div>
        </div>
      </Layout>
    </div>
  );
};
Page.routeProps = {
  header: 'secured',
  public: false,
  footer: false,
};
export default Page;
