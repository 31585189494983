import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { ApiForm } from '../../../containers/apiForm';
import { url } from '../../../constants/api';
/**
 * Profile Information Form
 *
 */
const Main = () => {
  const { user } = useCurrentUser();

  return (
    <>
      <p className="page-title">General Settings</p>
      <div className="setting user-information">
        <ApiForm
          submitButtonProps={{
            children: 'Save',
          }}
          apiUrl={url(`users/current/update`)}
          apiConfig={{
            method: 'PATCH',
          }}
          elements={[
            // {
            //   type: 'file',
            //   name: 'photoURL',
            //   label: 'Profile Image',
            //   required: false,
            //   options: {
            //     initialValue: { url: user.photoURL, type: 'image/png' },
            //   },
            //   fileCode: 'profileImage',
            // },
            {
              type: 'text',
              name: 'name',
              label: 'Name',
              required: true,
              options: {
                initialValue: user.name,
              },
            },
            {
              type: 'text',
              name: 'company',
              label: 'Company',
              required: true,
              options: {
                initialValue: user.company,
              },
              inputProps: {
                maxLength: 1000,
              },
            },
            {
              type: 'textarea',
              name: 'address',
              label: 'Address',
              required: true,
              options: {
                initialValue: user.address,
              },
              inputProps: {
                maxLength: 1000,
              },
            },
            {
              type: 'text',
              name: 'city',
              label: 'City',
              required: true,
              options: {
                initialValue: user.city,
              },
              inputProps: {
                maxLength: 100,
              },
            },
            {
              type: 'text',
              name: 'postCode',
              label: 'Post Code',
              required: true,
              options: {
                initialValue: user.postCode,
              },
              inputProps: {
                maxLength: 100,
              },
            },
          ]}
        />
      </div>
    </>
  );
};

export default Main;
