import { LineChartOutlined } from '@ant-design/icons';
import Link from '@arivaa-react/components/link';
import { Button, Tooltip } from 'antd';
export function VisualiseCmi({ serialnumber, online }) {
  return serialnumber ? (
    <Tooltip title="Visualisation">
      <Link
        routeKey="home.visualisation"
        routeParams={{
          serialnumber,
        }}
      >
        <Button
          className={`btn ${online ? 'yellow-btn-text' : ''}`}
          disabled={!online}
        >
          <LineChartOutlined />
        </Button>
      </Link>
    </Tooltip>
  ) : null;
}
