import { useCallback, useState, useEffect } from 'react';
import { useSecureApi } from '../../../hooks/api';
import { CrudProvider, defaultQuery, defaultCrudContext } from './crudContext';
import { Table } from './table';
import { url } from '../../../constants/api';
import { Header } from './header';
//TODO : Refactor this
import './styles.scss';
import { Search } from './search';
export function Crud({ children, api, query: initialQuery, FormComponent }) {
  const [query, setQuery] = useState({
    ...defaultQuery,
    ...initialQuery,
  });
  const { callApi, loading, data } = useSecureApi();
  const apiUrl = url(api);
  const updateQuery = useCallback(
    (updated) =>
      setQuery({
        ...query,
        ...updated,
      }),
    [query]
  );
  const getData = useCallback(() => {
    callApi(apiUrl, {
      params: query,
    });
  }, [query]);

  useEffect(() => {
    getData();
  }, [query]);

  useEffect(() => {
    updateQuery(initialQuery);
  }, [initialQuery]);

  return (
    <CrudProvider
      value={{
        ...defaultCrudContext,
        apiUrl,
        query,
        data,
        loading,
        updateQuery,
        refresh: getData,
        FormComponent,
      }}
    >
      <div className="smart-table">{children}</div>
    </CrudProvider>
  );
}

Crud.Table = Table;
Crud.Header = Header;
Crud.Search = Search;
