import OTPVerification from '../../containers/otpVerificationForm/index';
/**
 * @description Sign Up  via phone
 * @type Page
 * @author
 */
const Main = () => {
  return <OTPVerification />;
};

Main.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};

Main.displayName = 'Verify';
export default Main;
