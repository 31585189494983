import FormConfiguration from '../../../containers/form-configuration/index';

const emailInformation = () => {
  return (
    <FormConfiguration
      configStartPrefix="NODEMAILER"
      pageTitle={'Email Settings'}
    />
  );
};

export default emailInformation;
