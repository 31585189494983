import './styles.scss';
import { FrownOutlined } from '@ant-design/icons';

const Main = function ({ code, title, message }) {
  return (
    <div className="error-page 404 content-center">
      <div className="error">
        <p className="graphics">
          <FrownOutlined />
        </p>
        <p className="error-name">{code}</p>
        <p className="small-msg">{title}</p>
        <p className="descriptive-msg">{message}</p>
      </div>
    </div>
  );
};

Main.displayName = 'Error';
export default Main;
