import AuthFormWrapper from '../../authFormWrapper/index';
import authenticationOptions from './options';

/**
 * @description Signup Form
 * @type Component
 * @author Inderdeep
 */
const Main = ({ type, login }) => {
  const { SignupForm, Options, title } = authenticationOptions[type] || {};
  const optionKeys = Object.keys(authenticationOptions);
  return (
    <AuthFormWrapper title={title || 'Sign Up'}>
      {SignupForm && <SignupForm />}
      {Options && <Options />}
    </AuthFormWrapper>
  );
};
Main.displayName = 'Signup-Form';

export default Main;
