import Error from '../../containers/error/index';
const Main = () => (
  <Error
    code="500"
    title="Something went wrong"
    description="Sorry, we had me technical problems during your last operation."
  />
);

Main.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};

export default Main;
