let options = {};
export default options;

function requireAll(r) {
  r.keys()
    .map((r) => {
      if (r !== './index.js') {
        const name = r.replace('.js', '');
        const file = require(`${name}`);
        options[name.replace('./', '')] = file;
      }
      return r;
    })
    .forEach(r);
}
requireAll(require.context('./', true, /\.js$/));
