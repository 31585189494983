import './styles.scss';

/**
 * @description Html Preview
 * @type Component
 * @author Inderdeep
 */
const Main = ({ value, onClick, className }) => {
  return (
    <span
      className={className}
      onClick={() => onClick instanceof Function && onClick(value?.template)}
    >
      <iframe
        srcDoc={value?.template}
        frameBorder={0}
        onError={(e) => console.error('iframe error', e)}
        style={{
          pointerEvents: 'none',
        }}
      ></iframe>
    </span>
  );
};

export default Main;
