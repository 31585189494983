import Error from '../../containers/error/index';
const Main = () => (
  <Error
    code="404"
    title="Page not found"
    description="The page you are looking for does not exist."
  />
);

Main.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};

export default Main;
