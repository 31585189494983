import { jsUcfirst } from '@arivaa-react/helpers/common';

export const CMI_ACCESS_SELECT_DATA = Object.values(
  window.app.CMI_ACCESS || {}
).map((value) => {
  return {
    label: jsUcfirst(value),
    value,
  };
});

export const CMI_DISPLAY_PROFILES = [
  //'Temperatures 080618 ',
  'PROFILE#1',
  'PROFILE#2',
];
