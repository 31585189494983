import React, { useRef } from 'react';
import Form from '@arivaa-react/components/form';
import { message } from 'antd';
import { secureApi } from '../hooks/api';
import { url } from '../constants/api';
import { useSpinner } from '../hooks/useSpinner';
import { translate } from '../localization';

/**
 * @description Api Form Container
 * @type Container
 * @author Inderdeep
 */
export const ApiForm = React.forwardRef(
  (
    {
      onError,
      preSubmit,
      onSuccess,
      extra,
      successMessage,
      errorMessage,
      fileUploadErrorMessage,
      apiUrl,
      apiConfig,
      ...extraProps
    },
    ref
  ) => {
    ref = ref || useRef();
    const fileElements = (extraProps.elements || []).filter(
      ({ type }) => type === 'file'
    );
    const onSubmit = async (values) => {
      const spinner = useSpinner(ref.current?.formElement);
      try {
        let fileUploadError = null;
        if (preSubmit instanceof Function) {
          // Pass true to indicate its a update operation
          values = preSubmit(values);
          /**
           * If false is returned, assume not submit
           */
          if (!values) {
            return;
          }
        }
        spinner.start();
        //Check for files to be uploaded
        if (fileElements.length > 0) {
          await Promise.all(
            fileElements.map(
              async ({ fileCode, name, label, disableStorageUpload }) => {
                if (disableStorageUpload) {
                  return;
                }
                const uploadFile = (file) =>
                  secureApi(url`storage`, {
                    body: {
                      file: file.dataUri,
                      type: file.type,
                      name: file.name,
                      fileCode,
                    },
                    method: 'POST',
                  });
                try {
                  let data;
                  if (values[name] instanceof File) {
                    data = await uploadFile(values[name]);
                    values[name] = encodeURI(data.url);
                  } else if (values[name] instanceof Array) {
                    values[name] = await Promise.all(
                      values[name].map(async (file) => {
                        if (file instanceof File) {
                          return encodeURI((await uploadFile(file)).url);
                        } else {
                          return file;
                        }
                      })
                    );
                  } else {
                    values[name] = values[name]?.url;
                  }
                } catch (e) {
                  console.error('Error while uploading files ', {
                    e,
                    name,
                    fileCode,
                  });
                  fileUploadError = {
                    e,
                    name,
                    fileCode,
                    label,
                  };
                }
              }
            )
          );
          if (fileUploadError) {
            message.error(
              fileUploadErrorMessage ||
                errorMessage ||
                translate('common.changes.save.error')
            );
            throw fileUploadError;
          }
        }

        const response = await secureApi(apiUrl, {
          method: 'POST',
          ...apiConfig,
          body: {
            ...extra,
            ...values,
          },
        });
        if (onSuccess instanceof Function) {
          onSuccess(response, { values, spinner });
        } else {
          message.success(
            successMessage || translate('common.changes.save.success')
          );
        }
      } catch (err) {
        console.error({
          err,
        });
        if (onError instanceof Function) {
          onError(err, values);
        } else {
          message.error(
            err.message ||
              errorMessage ||
              translate('common.changes.save.error')
          );
        }
      }
      spinner.stop();
    };
    return <Form {...extraProps} onSubmit={onSubmit} ref={ref} />;
  }
);
