import { DatePicker, Spin } from 'antd';
import { url } from '../../../constants/api';
import { useApiWithSideEffects } from '../../../hooks/api';
import Form from '@arivaa-react/components/form';
import { CMI_DISPLAY_PROFILES } from '../../../helpers/cmi';
import { useCallback } from 'react';
import { Chart } from './chart';
import dayjs from 'dayjs';
import './style.scss';
//import data from './data.json'
export default function Visualisation({ match }) {
  const serialNumber = match.params?.serialnumber;
  const { callApi, loading, data } = useApiWithSideEffects({
    errorMessage: 'Error while processing the request',
    secure: true,
  });
  const onSubmit = useCallback(({ profile, dateRange }) => {
    const [from, to] = dateRange;
    callApi(url`user-cmi/visualisation`, {
      method: 'POST',
      body: {
        serialNumber,
        profile,
        from: from.format('DD-MM-YYYY'),
        to: to.format('DD-MM-YYYY'),
      },
    });
  }, []);
  return (
    <div className="request-form visualisation">
      <p className="page-title">Anchor : {serialNumber}</p>
      <Spin spinning={loading}>
        <Form
          elements={[
            {
              type: 'inline',
              elements: [
                {
                  type: 'select',
                  name: 'profile',
                  label: 'Select Display Profile',
                  data: CMI_DISPLAY_PROFILES,
                  required: true,
                  options: {
                    initialValue: CMI_DISPLAY_PROFILES[0],
                  },
                  inputProps: {
                    placeholder: 'Select Display Profile',
                  },
                },
                {
                  type: 'custom',
                  name: 'dateRange',
                  label: 'Date Range',
                  required: true,
                  Component: DatePicker.RangePicker,
                  inputProps: {
                    maxDate: dayjs(),
                  },
                },
              ],
            },
          ]}
          submitButtonProps={{
            children: 'Load Data',
          }}
          onSubmit={onSubmit}
        />
      </Spin>
      <Chart data={data} />
    </div>
  );
}
Visualisation.url = 'visualisation/:serialnumber';
Visualisation.routeProps = {
  //role: 'USER',
};
