import { Button, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { AddRecord } from './header-actions/addRecord';
import { Refresh } from './header-actions/refresh';
import { SampleCsvExport } from './header-actions/sampleCsvExport';
import { CsvImport } from './header-actions/csvImport';
import { Fragment } from 'react';
function Secondary({ children }) {
  return (
    <Dropdown
      menu={{
        items: (Array.isArray(children) ? children : [children]).map(
          (label, index) => ({
            key: index,
            label,
          })
        ),
      }}
      placement="bottomRight"
    >
      <Button type="primary" icon={<MoreOutlined />} />
    </Dropdown>
  );
}

export function HeaderActions({ children }) {
  return <div className="actions">{children}</div>;
}

HeaderActions.Secondary = Secondary;
HeaderActions.Add = AddRecord;
HeaderActions.Refresh = Refresh;
HeaderActions.SampleCsvExport = SampleCsvExport;
HeaderActions.CsvImport = CsvImport;

/**
 * Replaces React children in an array with the overridden children.
 *
 * @param {Array} defaultElementsArray - The array of default elements.
 * @param {Array} children - The array of children to replace the duplicate elements with.
 * @return {Array} The updated array with the duplicate elements replaced.
 */
function replaceReactChildren(defaultElementsArray = [], children = []) {
  const elements = [...defaultElementsArray];
  children.forEach((child) => {
    const index = elements.findIndex((item) => item.type === child.type);
    if (index !== -1) {
      elements[index] = child;
    }
  });
  return elements;
}

HeaderActions.Common = ({ children = [], secondaryActions = [] }) => {
  children = Array.isArray(children) ? children : [children];
  // We are doing this replacement to allow the user to override the default actions
  const primary = replaceReactChildren(
    [<HeaderActions.Add key={0} />, <HeaderActions.Refresh key={1} />],
    children
  );
  const secondary = replaceReactChildren(
    [
      <HeaderActions.SampleCsvExport key={0} />,
      <HeaderActions.CsvImport key={1} />,
    ],
    secondaryActions
  );

  return (
    <HeaderActions>
      {primary.map((item, index) => (
        <Fragment key={index}>{item}</Fragment>
      ))}

      <Secondary>
        {secondary.map((item, index) => (
          <Fragment key={index}>{item}</Fragment>
        ))}
      </Secondary>
    </HeaderActions>
  );
};
