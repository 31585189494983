import './styles.scss';
import logo from '../../images/logo.png';
import Link from '@arivaa-react/components/link';

/**
 * @description Sample Container
 * @type Container
 * @author Inderdeep
 */
const Main = ({ title, html }) => {
  return (
    <div className="public-page terms">
      <div className="header">
        <div className="container">
          <div className="logo">
            <Link routeKey="signup" routeParams={{ type: 'email' }}>
              <img src={logo} />
            </Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="section">
          <div className="heading">
            {title && title !== '' && (
              <div className="title">
                <p>{title}</p>
              </div>
            )}
          </div>
          <div className="details">
            <div
              className="editor-content"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

Main.displayName = 'Static-Page';
export default Main;
