import { ALPHANUMERIC_HYPENS } from '@arivaa-react/components/regex';
import { ColorPicker } from 'antd';
import { Crud } from '../../containers/crud/crud';
import { CrudForm } from '../../containers/crud/crudForm';
import { useCrudData } from '../../containers/crud/crudContext';
import { translate } from '../../../localization';
export default function Theme() {
  const { data } = useCrudData();

  return (
    <Crud
      api="theme"
      FormComponent={(props) => (
        <CrudForm
          {...props}
          elements={[
            {
              type: 'text',
              name: 'key',
              label: translate('theme.key.label'),
              required: true,
              options: {
                rules: [
                  {
                    pattern: ALPHANUMERIC_HYPENS,
                    message:
                      'Key can only be alphanumeric and can contain only hyphens (-)',
                  },
                ],
              },
              inputProps: {
                disabled: !!props.data,
                className: 'uppercase',
              }, //need to be fixed
            },
            {
              type: 'custom',
              name: 'value',
              label: translate('theme.value.label'),
              Component: (props) => (
                <ColorPicker
                  {...props}
                  onChange={(val) => props.onChange?.(val?.toHexString())}
                />
              ),
              required: true,
              inputProps: {
                format: 'hex',
              },
            },
            {
              type: 'textarea',
              name: 'description',
              label: translate('theme.description.label'),
            },
          ]}
        ></CrudForm>
      )}
    >
      <Crud.Header title="Themes">
        <Crud.Header.Actions.Common />
      </Crud.Header>
      <Crud.Table
        actions={[
          ...Crud.Table.RowActions.Common,
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'text',
                  label: 'Enter new key',
                  name: 'key',
                  inputProps: {
                    placeholder: 'Key',
                  },
                },
              ]}
            />
          ),
        ]}
        columns={[
          {
            title: 'Code',
            dataIndex: 'key',
          },
          {
            title: 'Color',
            dataIndex: 'value',
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                name: 'key',
                type: 'text',
                inputProps: {
                  placeholder: 'key',
                },
              },
            ]}
          ></Crud.Search>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </Crud>
  );
}
