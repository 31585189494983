import ForgotForm from '../../containers/authentication/forgotForm/index';
import { DEFAULT_LOGIN_TYPE } from '../../containers/authentication/defaultLoginLink';

/**
 * @description Forgot Password
 * @type Page
 * @author
 */
const Main = ({ match }) => {
  return <ForgotForm type={match?.params?.type || DEFAULT_LOGIN_TYPE} />;
};

Main.displayName = 'Forgot';
Main.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};
Main.url = 'forgot/:type?';
export default Main;
