import SignUpForm from '../../containers/authentication/signUpForm/index';
import { DEFAULT_LOGIN_TYPE } from '../../containers/authentication/defaultLoginLink';
/**
 * @description Sign Up  via phone
 * @type Page
 * @author
 */
const Main = ({ match }) => {
  return (
    <>
      <SignUpForm type={match?.params?.type || DEFAULT_LOGIN_TYPE} />
    </>
  );
};

Main.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};
Main.url = 'signup/:type?';
Main.displayName = 'Signup';
export default Main;
