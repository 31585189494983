import createSmartInput from '@arivaa-react/components/createSmartInput';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { getAppConfig } from '../../../hooks/useAppConfig';

const Captcha = createSmartInput(
  React.forwardRef((props, captchaRef) => {
    return (
      <ReCAPTCHA
        onChange={(token) => {
          props.onChange(token);
        }}
        ref={captchaRef}
        sitekey={getAppConfig(({ captcha }) => captcha?.siteKey)}
      />
    );
  })
);

export default Captcha;
