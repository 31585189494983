import './styles.scss';
import ProfileInformation from './profile-information';
/**
 * @description General Settings Page
 * @type Container
 * @author Inderdeep
 */
const Page = () => {
  return <ProfileInformation />;
};

Page.displayName = 'General-Settings';

Page.routeProps = {
  header: false,
};
export default Page;
