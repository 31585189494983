import AuthFormWrapper from '../../authFormWrapper/index';
import authenticationOptions from './options';

/**
 * @description Forgot Form
 * @type Component
 * @author Inderdeep
 */
const Main = ({ type, login }) => {
  const { ForgotForm, Options, title, description } =
    authenticationOptions[type] || {};
  const optionKeys = Object.keys(authenticationOptions);
  return (
    <AuthFormWrapper
      title={title || 'Forgot Password'}
      description={description || 'Receive a new password reset code via email'}
    >
      {ForgotForm && <ForgotForm />}
      {/* {optionKeys.length > 1 && (
        <div className="separator">
          <span className="line" />
          <span>OR RESET WITH</span>
          <span className="line" />
        </div>
      )}
      <div className="links">
        {optionKeys.map((key) => {
          const { ButtonLink } = authenticationOptions[key];
          if (type === key) {
            return null;
          } else {
            return ButtonLink && <ButtonLink key={key} login={login} />;
          }
        })}
      </div> */}
      {Options && <Options />}
    </AuthFormWrapper>
  );
};
Main.displayName = 'Forgot-Form';

export default Main;
