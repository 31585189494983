import { message } from 'antd';
import { Crud } from '../../containers/crud/crud';
import { CrudForm } from '../../containers/crud/crudForm';
import { Table } from '../../containers/crud/table';
import { translate } from '../../../localization';
import { VisualiseCmi } from './visualiseCmi';
import { useCrudRecord } from '../../containers/crud/recordContext';
import { useFeatureAccess } from '../../../hooks/useFeatureAccess';
import { RowActions } from '../../containers/crud/rowActions';
import { CheckCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { CmiDiagram } from './cmiDiagram';
const formElements = [
  {
    type: 'text',
    name: 'serialNo',
    label: 'Serial Number',
    required: true,
  },
  {
    type: 'text',
    name: 'username',
    label: 'Creator',
    required: true,
  },
  {
    type: 'textarea',
    name: 'description',
    label: 'Description',
    required: false,
  },
  {
    type: 'file',
    name: 'file',
    label: 'Assets',
    required: false,
    disableStorageUpload: true,
  },
];
export default function Cmi({ permissionMap }) {
  const { hasAccess } = useFeatureAccess();
  const { user } = useCurrentUser();
  return (
    <Crud
      api="cmi"
      query={{
        populate: true,
      }}
      FormComponent={(props) => (
        <CrudForm
          {...props} //
          elements={formElements}
          onError={(error) => {
            if (error?.status === 409) {
              message.error(
                `There already exists an anchor with this ${
                  formElements.find(({ name }) => name === error?.details?.key)
                    ?.label
                }`
              );
            } else {
              message.error(
                error.message || translate('common.changes.save.error')
              );
            }
          }}
        ></CrudForm>
      )}
    >
      <Crud.Header title="Anchors">
        {hasAccess([], [permissionMap.create]) && <Crud.Header.Actions.Add />}
        <Crud.Header.Actions.Refresh />
      </Crud.Header>
      <Crud.Table
        actions={[]}
        columns={[
          {
            title: 'Serial Number',
            dataIndex: 'serialNo',
          },
          {
            title: 'Description',
            dataIndex: 'description',
          },
          {
            title: 'Online',
            dataIndex: 'online',
            sorter: false,
            render: (value) =>
              value ? (
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ fontSize: '20px' }}
                />
              ) : (
                <MinusCircleTwoTone
                  twoToneColor="red"
                  style={{ fontSize: '20px' }}
                />
              ),
          },
          {
            title: 'Actions',
            dataIndex: 'actions',
            sorter: false,
            render: (_, record) => (
              <RowActions
                record={record}
                actions={[
                  ...(hasAccess([], [permissionMap.edit])
                    ? [Table.RowActions.Edit]
                    : []),
                  ...(hasAccess([], [permissionMap.delete])
                    ? [Table.RowActions.Delete]
                    : []),
                  () => {
                    const data = useCrudRecord();
                    return (
                      <VisualiseCmi
                        serialnumber={data.serialNo}
                        online={record.online}
                      />
                    );
                  },
                  () => {
                    const data = useCrudRecord();
                    return (
                      <CmiDiagram
                        serialnumber={data.serialNo}
                        online={record.online}
                      />
                    );
                  },
                ]}
              />
            ),
          },

          ...(user?.role === 'ADMIN'
            ? [
                {
                  title: 'Creator',
                  dataIndex: 'username',
                },
              ]
            : []),
        ]}
      >
        {/* <Crud.Table.TableActions.Common /> */}
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                type: 'text',
                name: 'serialNo',
                inputProps: {
                  placeholder: 'Anchor Serial Number',
                },
              },
              {
                type: 'text',
                name: 'username',
                inputProps: {
                  placeholder: 'Creator',
                },
              },
            ]}
          />
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </Crud>
  );
}
