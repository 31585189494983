import { Switch, Tabs } from 'antd';
import { Crud } from '../../containers/crud/crud';
import { CrudForm } from '../../containers/crud/crudForm';
import { Table } from '../../containers/crud/table';
import { UserSelect } from './userSelect';
import { jsUcfirst } from '@arivaa-react/helpers/common';
import { CmiSelect } from './cmiSelect';
import UpdateEntity from '../../containers/update-entity';
import { useCrudData } from '../../containers/crud/crudContext';
import { useState } from 'react';
import { CMI_ACCESS_SELECT_DATA } from '../../../helpers/cmi';

const CmiTable = () => {
  const { refresh } = useCrudData();
  return (
    <Crud.Table
      actions={[
        ...Table.RowActions.Common,
        // () => {
        //   const data = useCrudRecord();
        //   return <VisualiseCmi serialnumber={data.cmi?.serialNo} />;
        // },
      ]}
      columns={[
        {
          title: 'Client',
          dataIndex: 'displayName',
          sorter: false,
        },
        {
          title: 'Anchor Serial Number',
          dataIndex: ['cmi', 'serialNo'],
          sorter: false,
        },
        {
          title: 'Description',
          dataIndex: ['cmi', 'description'],
          sorter: false,
        },
        {
          title: 'Access',
          dataIndex: 'access',
          render: (value) => jsUcfirst(value),
        },
        {
          title: 'Approved',
          dataIndex: 'approved',
          render: (_, record) => {
            return (
              <UpdateEntity
                keyPropName="key"
                getChildren={({ loading, updateEntity }) => {
                  return (
                    <Switch
                      loading={loading}
                      checked={record.approved}
                      onChange={async (checked) => {
                        await updateEntity({ approved: checked });
                        refresh();
                      }}
                    />
                  );
                }}
              />
            );
          },
        },
      ]}
    >
      {/* <Crud.Table.TableActions.Common /> */}
      <Crud.Table.Header>
        <Crud.Search
          fields={[
            {
              type: 'custom',
              name: 'userId',
              Component: UserSelect,
            },
            {
              type: 'custom',
              name: 'cmiId',
              Component: CmiSelect,
            },
            {
              type: 'select',
              name: 'access',
              inputProps: {
                placeholder: 'Access',
              },
              data: CMI_ACCESS_SELECT_DATA,
            },
          ]}
        />
        <Crud.Table.BulkRowActions.Common />
      </Crud.Table.Header>
    </Crud.Table>
  );
};

export default function Cmi() {
  const [query, setQuery] = useState();
  const onTabChange = (key) => {
    if (key === 'all') {
      setQuery({
        search: [],
      });
    } else if (key === 'approved') {
      setQuery({
        search: [`approved;1`],
      });
    } else if (key === 'pending') {
      setQuery({
        search: [`approved;0`],
      });
    }
  };
  return (
    <Crud
      api="user-cmi"
      query={{
        populate: true,
        ...query,
      }}
      FormComponent={(props) => (
        <CrudForm
          {...props}
          elements={[
            {
              type: 'custom',
              name: 'userId',
              label: 'Client',
              required: true,
              Component: UserSelect,
            },
            {
              type: 'custom',
              name: 'cmiId',
              label: 'Anchor',
              required: true,
              Component: CmiSelect,
            },
            {
              type: 'select',
              name: 'access',
              label: 'Access',
              required: true,
              data: CMI_ACCESS_SELECT_DATA,
            },
          ]}
        ></CrudForm>
      )}
    >
      <Crud.Header title="Anchor Access Management">
        <Crud.Header.Actions.Add />
        <Crud.Header.Actions.Refresh />
      </Crud.Header>

      <Tabs
        defaultActiveKey="all"
        destroyInactiveTabPane={true}
        onChange={onTabChange}
        items={[
          {
            label: 'All',
            key: 'all',
            children: <CmiTable />,
          },
          {
            label: 'Approved Access',
            key: 'approved',
            children: <CmiTable />,
          },
          {
            label: 'Pending Requests',
            key: 'pending',
            children: <CmiTable />,
          },
        ]}
      />
    </Crud>
  );
}
