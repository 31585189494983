import ForgotForm from '../../containers/authentication/forgotForm/index';

/**
 * @description Forgot Password Via Phone
 * @type Page
 * @author
 */
const Main = () => {
  return (
    <>
      <ForgotForm isPhoneBased={true} />
    </>
  );
};

Main.displayName = 'Forgot-Phone';
Main.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};
export default Main;
