import { useRef } from 'react';
import './styles.scss';
import { Layout, Avatar, Popover, Menu } from 'antd';
import Link from '@arivaa-react/components/link';
import MenuKeys from '../../sider/config';
import { goToRoute } from '@arivaa-react/react-router5';
import DefaultLoginLink from '../../authentication/defaultLoginLink';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useDrawer } from '../../../hooks/useDrawer';
import { useHorizontalMenu } from '../../../hooks/useHorizontalMenu';
import { translate } from '../../../localization';
const { SubMenu, Item } = Menu;

const { Header } = Layout;
const Main = () => {
  const profilePopoverMenu = useRef(null);
  const { user } = useCurrentUser();
  const { drawer, toggleDrawer } = useDrawer();
  const { horizontalMenu } = useHorizontalMenu();

  if (!user) {
    return null;
  }
  const { role } = user;

  let menus = MenuKeys.filter((item) => {
    if (item.role instanceof Function) {
      return item.role(role);
    }
    if (typeof item.role == 'undefined') {
      return true;
    }
    return (item.role || '').toLowerCase() === (role || '').toLowerCase();
  });

  const content = (
    <div className="popover-content">
      <div className="user-info">
        {/* <Avatar
          icon={<UserOutlined />}
          shape="square"
          size="large"
          src={getProfilePicture(user, {
            height: 100,
          })}
        /> */}
        <Avatar size="large">{user.displayName.charAt(0)}</Avatar>
        <div className="user">
          <p className="name">{user.displayName}</p>
          <p className="email">{user.email}</p>
        </div>
      </div>
      <div className="other-links">
        <ul>
          <li className="mobile-only">
            <Link routeKey="profile">{translate('header.profile')}</Link>
          </li>
          <li>
            <DefaultLoginLink>{translate('common.logout')}</DefaultLoginLink>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <Header
      className={
        'header secured ' + (horizontalMenu ? 'with-horizontal-menu' : {})
      }
    >
      <div className="top-section">
        <div className="left">
          <div className="trigger drawer">
            {drawer ? (
              <MenuFoldOutlined
                className={'trigger-icon'}
                onClick={toggleDrawer}
              />
            ) : (
              <MenuUnfoldOutlined
                className={'trigger-icon'}
                onClick={toggleDrawer}
              />
            )}
          </div>
        </div>
        <div className="right">
          <div className="navigation">
            <ul>
              <li>
                <Link className="profile-link">
                  <Popover
                    ref={profilePopoverMenu}
                    placement="bottomRight"
                    content={content}
                    trigger="click"
                    className="header-popover"
                  >
                    <div className="user-image">
                      {/* <Avatar
                        icon={<UserOutlined />}
                        shape="square"
                        src={getProfilePicture(user, {
                          height: 100,
                        })}
                        
                      /> */}

                      <Avatar shape="circle" size="large">
                        {user.displayName.charAt(0)}
                      </Avatar>
                    </div>
                  </Popover>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {horizontalMenu ? (
        <div className="bottom-section">
          <div className="horizontal-menu">
            <Menu
              theme="light"
              mode="horizontal"
              onClick={(e) => goToRoute(e.key)}
            >
              {menus.map((item) => {
                const { icon: Icon, name, children, key } = item;
                if (children) {
                  return (
                    <SubMenu key={key} title={name}>
                      {children.map((child) => {
                        const { icon, name, key } = child;
                        return (
                          <Item key={key}>
                            <Icon />
                            <span>{name}</span>
                          </Item>
                        );
                      })}
                    </SubMenu>
                  );
                } else {
                  return (
                    <Item key={key}>
                      <Icon />
                      <span>{name}</span>
                    </Item>
                  );
                }
              })}
            </Menu>
          </div>
        </div>
      ) : null}
    </Header>
  );
};

Main.displayName = 'Secure-Header';
export default Main;
