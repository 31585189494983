import { useEffect, useRef, useState } from 'react';
import { url } from '../../../constants/api';
import { useAuthApi } from '../../../hooks/useAuthApi';
import { Spin } from 'antd';

export default function Diagrams({ match }) {
  const { auth } = useAuthApi();
  const iframeRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const serialNumber = match.params?.serialnumber;
  useEffect(() => {
    if (iframeRef.current)
      iframeRef.current.contentWindow.onunload = () => setLoading(true);
  }, []);
  return serialNumber ? (
    <div>
      <p className="page-title">Anchor : {serialNumber}</p>
      <Spin spinning={loading} size="large" tip="Please wait...">
        <iframe
          ref={iframeRef}
          src={url(
            `user-cmi/diagram/${serialNumber}/create?authorization=${auth.accessToken}`
          )}
          height="726px"
          width="100%"
          onLoad={() => setLoading(false)}
        />
      </Spin>
    </div>
  ) : null;
}

Diagrams.url = 'diagram/:serialnumber'; //
