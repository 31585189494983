import AuthFormWrapper from '../../authFormWrapper/index';
import authenticationOptions from './options';
import { useSpinner } from '../../../hooks/useSpinner';
import { useLogin } from '../useLogin';

/**
 * @description Login Form
 * @type Component
 * @author Inderdeep
 */
const Main = ({ type }) => {
  const { login } = useLogin(useSpinner('.auth-form'));
  const { LoginForm, Options, title } = authenticationOptions[type] || {};
  const optionKeys = Object.keys(authenticationOptions);
  return (
    <AuthFormWrapper title={title || 'Log In'}>
      {LoginForm && <LoginForm login={login} />}
      {Options && <Options login={login} />}
    </AuthFormWrapper>
  );
};
Main.displayName = 'Login-Form';

export default Main;
