import { useEffect, useState } from 'react';
import { getAppConfig } from '../hooks/useAppConfig';
import { useSpinner } from '../hooks/useSpinner';
export default (Main, config) => {
  config = config || {};
  const showPageSpinner = !!Main.showPageSpinner;
  let App = (props) => {
    const [pageData, setPageData] = useState(props.pageData);
    const [loading, setLoading] = useState(false);
    const getData = async () => {
      const spinner = useSpinner('.app-container');
      try {
        if (
          props.getPageData instanceof Function &&
          props.pageData === undefined
        ) {
          if (showPageSpinner) {
            spinner.start('.app-container');
          } else {
            setLoading(true);
          }
          setPageData(
            await props.getPageData(props.route, {
              ...props.match,
            })
          );
        } else {
          setPageData(props.pageData);
        }
      } catch (e) {
        console.error('Error while getting page data', { e });
      }
      if (showPageSpinner) {
        spinner.stop('.app-container');
      } else {
        setLoading(false);
      }
      const metaTags =
        props.getMetaTags instanceof Function
          ? props.getMetaTags(props.pageData)
          : {};
      window.document.title = metaTags.title || getAppConfig('defaultTitle');
    };
    useEffect(() => {
      getData();
    }, [props.match.url]);
    return (
      <Main
        {...props}
        loading={loading}
        pageData={pageData}
        getPageData={getData}
      />
    );
  };
  return App;
};
