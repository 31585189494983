import { InputNumber } from 'antd';
import createSmartInput from '@arivaa-react/components/createSmartInput';
import { useOtpInput } from '../../hooks/useOtpInput';

export const OtpInput = createSmartInput(({ inputProps, ...props }) => {
  const { value, refArr, onChangeHandler } = useOtpInput(
    props.value,
    props.onChange
  );
  return refArr.map((ref, index) => {
    return (
      <InputNumber
        min={0}
        max={9}
        key={index}
        ref={ref}
        maxLength={1}
        value={value[index]}
        controls={false}
        onChange={(val) => onChangeHandler(index, val)}
        onKeyUpCapture={(e) => {
          if (e.key === 'Backspace') {
            refArr[index - 1]?.current?.focus();
          }
        }}
        className="otp-input"
        {...inputProps}
      />
    );
  });
});
