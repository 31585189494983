import { url } from '../../../constants/api';
import { ApiForm } from '../../../containers/apiForm';
import { CMI_ACCESS_SELECT_DATA } from '../../../helpers/cmi';
import './styles.scss';

export default function RequestAnchorAccess() {
  return (
    <div className="request-form">
      <p className="page-title">Request Anchor Access</p>
      <ApiForm
        successMessage="Request has been sent. Please wait for update"
        elements={[
          {
            type: 'text',
            name: 'serialNumber',
            label: 'Anchor Serial Number',
            required: true,
          },
          {
            type: 'select',
            name: 'access',
            label: 'Access Required',
            data: CMI_ACCESS_SELECT_DATA,
            required: true,
          },
        ]}
        apiUrl={url`user-cmi/request-access`}
      />
    </div>
  );
}

RequestAnchorAccess.routeProps = {
  roles: ['USER'],
};
