import { PieChartFilled } from '@ant-design/icons';
import Link from '@arivaa-react/components/link';
import { Button, Tooltip } from 'antd';
export function CmiDiagram({ serialnumber, online }) {
  return serialnumber ? (
    <Tooltip title="Diagram">
      <Link
        routeKey="home.diagram"
        routeParams={{
          serialnumber,
        }}
      >
        <Button
          className={`btn ${online ? 'yellow-btn-text' : ''}`}
          disabled={!online}
        >
          <PieChartFilled />
        </Button>
      </Link>
    </Tooltip>
  ) : null;
}
