import { Row, Col } from 'antd';
import { useTheme } from '../../hooks/useTheme';
import bg from '../../images/bg.jpg';
import logo from '../../images/logo-white.png';
import './styles.scss';
/**
 * @description Auth Form Wrapper for all auth related forms like login, signup etc
 * @type Component
 * @author Inderdeep
 */
const Main = ({ children, title, description }) => {
  const { primaryColor } = useTheme().colors;
  return (
    <Row>
      <Col xs={0} sm={0} md={10} lg={10} xl={10}>
        <div
          className="rectangle image"
          style={{ backgroundImage: 'url(' + bg + ')' }}
        >
          <div
            className="overlay"
            style={{
              background: `linear-gradient(150deg, #16a085 35%, #1abc9c 100%)`,
            }}
          />
          <div className="content">
            <p className="title">Welcome to</p>
            <div className="logo">
              <img src={logo} />
            </div>
            <p className="description">
              The dynamic web portal that serves as a seamless link to your
              ENVIGOR controlled and enhanced systems.
            </p>
            <p className="description">
              This web portal enables easy, remote access to adjust, control and
              monitor your complete portfolio all from one place.
            </p>
            <p className="description">
              Automatic early warning email notifications on potential system
              issues, with the added benefit of being able to add multiple users
              and key Service Engineers. All keeping a watchful eye on your
              assets
            </p>
          </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={14} lg={14} xl={14}>
        <div className="form-container">
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="auth-form">
            {title && <p className="title">{title}</p>}
            {description && <p className="description">{description}</p>}
            {children}
          </div>
        </div>
      </Col>
    </Row>
  );
};
Main.displayName = 'Auth-Form-Wrapper';

export default Main;
