import { Select, Spin } from 'antd';
import { useSecureApi } from '../../../hooks/api';
import { url } from '../../../constants/api';
import debounce from 'lodash/debounce';
import createSmartInput from '@arivaa-react/components/createSmartInput';
import { forwardRef, useEffect } from 'react';

export const CmiSelect = createSmartInput(
  forwardRef(({ value, onChange, selectProps }, ref) => {
    const { callApi, data, loading } = useSecureApi();
    useEffect(() => {
      if (value) {
        callApi(url`cmi`, {
          params: {
            search: [`key;${value};like`],
            all: true,
          },
        });
      }
    }, []);
    return (
      <Select
        ref={ref}
        style={{ width: '100%' }}
        loading={loading}
        filterOption={false}
        notFoundContent={loading ? <Spin size="small" /> : null}
        showSearch={true}
        onSearch={debounce(
          (text) =>
            callApi(url`cmi`, {
              params: {
                search: [`serialNo;${text};like`],
                all: true,
                expert: true,
              },
            }),
          800
        )}
        placeholder="Search anchor by Serial Number"
        {...selectProps}
        options={(data || []).map(({ serialNo: label, key: value }) => ({
          label,
          value: value?.toString(),
        }))}
        value={value?.toString()}
        onSelect={onChange}
      />
    );
  })
);
