import { Select, Spin } from 'antd';
import { useSecureApi } from '../../../hooks/api';
import { url } from '../../../constants/api';
import debounce from 'lodash/debounce';
import createSmartInput from '@arivaa-react/components/createSmartInput';
import { forwardRef, useEffect } from 'react';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
export const UserSelect = createSmartInput(
  forwardRef(({ value, onChange, selectProps }, ref) => {
    const { callApi, data, loading } = useSecureApi();
    const { key: userId } = useCurrentUser().user;
    useEffect(() => {
      if (value) {
        callApi(url`users`, {
          params: {
            search: [`key;${value};like`],
            all: true,
          },
        });
      }
    }, []);
    return (
      <Select
        ref={ref}
        style={{ width: '100%' }}
        loading={loading}
        filterOption={false}
        notFoundContent={loading ? <Spin size="small" /> : null}
        showSearch={true}
        onSearch={debounce(
          (text) =>
            text?.trim() !== ''
              ? callApi(url`users`, {
                  params: {
                    search: [
                      `displayName;${text};like`,
                      `key;${userId};ne;number`,
                    ],
                    all: true,
                  },
                })
              : null,
          800
        )}
        placeholder="Search client by name"
        {...selectProps}
        options={(data || []).map(({ name, email, key: value }) => ({
          label: `${name} (${email})`,
          value: value?.toString(),
        }))}
        value={value?.toString()}
        onSelect={onChange}
      />
    );
  })
);
