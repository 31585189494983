import {
  DesktopOutlined,
  IdcardOutlined,
  SettingOutlined,
  TeamOutlined,
  ScheduleOutlined,
  FileProtectOutlined,
} from '@ant-design/icons';

export default [
  {
    key: 'home.dashboard',
    name: 'sider.dashboard',
    icon: DesktopOutlined,
  },
  // {
  //   key: 'home.audit-log',
  //   name: 'Audit log',
  //   icon: ControlOutlined,
  // },
  // {
  //   key: 'home.dashboard',
  //   name: 'sider.dashboard',
  //   icon: DesktopOutlined,
  // },
  // { key: 'home.blog-post', name: 'sider.blog-post', icon: EditOutlined },
  // {
  //   key: 'home.blog-category',
  //   name: 'sider.blog-category',
  //   icon: AppstoreOutlined,
  // },
  // {
  //   key: 'home.backup',
  //   name: 'sider.backup',
  //   icon: SyncOutlined,
  // },
  // {
  //   key: 'home.permission',
  //   name: 'sider.permissions',
  //   icon: CarryOutOutlined,
  //   role: 'admin',
  // },
  // {
  //   key: 'home.sms-template',
  //   name: 'sider.sms.templates',
  //   icon: MobileOutlined,
  //   role: 'admin',
  // },
  // {
  //   key: 'home.page-configuration',
  //   name: 'sider.system.pages.configuration',
  //   icon: SnippetsOutlined,
  //   role: 'admin',
  // },
  {
    key: 'home.users',
    name: 'sider.users',
    icon: TeamOutlined,
    isAllowed: (permissions, role) => role?.toLowerCase() === 'admin',
  },
  {
    key: 'home.cmi',
    name: 'Anchors',
    noTranslate: true,
    icon: IdcardOutlined,
  },
  {
    key: 'home.user-cmi',
    noTranslate: true,
    name: 'Anchor Access Management',
    icon: ScheduleOutlined,
  },
  {
    key: 'home.request-anchor-access',
    name: 'Request Anchor Access',
    noTranslate: true,
    icon: FileProtectOutlined,
    isAllowed: (permissions, role) => role?.toLowerCase() === 'user',
  },
  // {
  //   key: 'home.roles',
  //   name: 'sider.roles',
  //   icon: IdcardOutlined,
  //   role: 'admin',
  // },
  // {
  //   key: 'home.contact',
  //   name: 'sider.enquiries',
  //   icon: QuestionCircleOutlined,
  //   role: 'admin',
  // },
  // {
  //   key: 'home.configuration',
  //   name: 'sider.configurations',
  //   icon: FileProtectOutlined,
  //   role: 'admin',
  // },
  // {
  //   key: 'home.page',
  //   name: 'sider.static.pages',
  //   icon: FileOutlined,
  //   role: 'admin',
  // },
  // {
  //   key: 'home.email-template',
  //   name: 'sider.email.templates',
  //   icon: MailOutlined,
  //   role: 'admin',
  // },
  // {
  //   key: 'home.theme',
  //   name: 'sider.theming',
  //   icon: BlockOutlined,
  //   role: 'admin',
  // },
  // {
  //   key: 'home.pdf-template',
  //   name: 'sider.pdf.templates',
  //   icon: FilePdfOutlined,
  //   role: 'admin',
  // },
  // {
  //   key: 'home.system-log',
  //   name: 'System Logs',
  //   icon: SettingOutlined,
  //   role: 'admin',
  // },
  // {
  //   key: 'home.unsubscribed-emails',
  //   name: 'Unsubscribed Emails',
  //   icon: MailOutlined,
  //   role: 'admin',
  // },
  // {
  //   key: 'home',
  //   name: 'System Settings',
  //   icon: SettingOutlined,
  //   role: 'admin',
  //   children: [
  //     {
  //       key: 'home.email-settings',
  //       name: 'Email Settings',
  //       icon: FilePdfOutlined,
  //     },
  //     {
  //       key: 'home.sms-settings',
  //       name: 'SMS Settings',
  //       icon: MailOutlined,
  //     },
  //   ],
  // },
  { key: 'settings.general', name: 'sider.settings', icon: SettingOutlined },
  /*{
    key: 'userGuide',
    name: (
      <a
        href="https://www.staging.envigor.co.uk/user-guide/"
        target="_blank"
        rel="noreferrer"
      >
        User Guide
      </a>
    ),
    icon: InfoCircleOutlined,
    noTranslate: true,
  },*/
];
