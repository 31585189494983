import React, {
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
} from 'react';
import './styles.scss';
import { Pagination, DatePicker, Checkbox, Button, Input, Radio } from 'antd';
import { useSecureApi } from '../../../hooks/api';
import { url } from '../../../constants/api';

const { Search } = Input;
const { RangePicker } = DatePicker;

const selectOptions = ['ERROR', 'WARN', 'INFO'];
const regex = /\b(ERROR|INFO|WARN)\b/g;

const Main = (props) => {
  const [logsdata, setLogsdata] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(Date.now() + 432000000);
  const [checkList, setCheckList] = useState(selectOptions);
  const [checkAll, setCheckAll] = useState(true);
  const [search, setSearch] = useState('');
  const preRef = useRef(null);
  const { callApi } = useSecureApi();

  useEffect(() => {
    preRef.current.scrollTop = preRef.current.scrollHeight;
  }, [logsdata]);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const logs = await callApi(url`system-log/getlogs`, {
          params: {
            page,
            limit,
            startDate,
            endDate,
            checkList,
            search,
          },
        });
        setLogsdata(logs);
      } catch (e) {
        console.error('Error while getting logs', e);
      }
    };
    fetchLogs();
    const intervalId = setInterval(fetchLogs, 10000);
    return () => clearInterval(intervalId);
  }, [page, startDate, endDate, limit, checkList, search]);

  const onPageChange = useCallback((pageIndex, limit) => {
    setPage(pageIndex);
    setLimit(limit);
  }, []);

  const onDateChange = useCallback((_, dates) => {
    //getTime to convert date in miliseconds
    setStartDate(new Date(dates[0]).getTime());
    setEndDate(new Date(dates[1]).getTime());
  }, []);

  const onCheckBoxChange = useCallback((checkedValues) => {
    setCheckList(checkedValues);
    if (checkedValues.length < selectOptions.length) {
      setCheckAll(false);
    } else {
      setCheckAll(true);
    }
  }, []);

  const onCheckAllChange = useCallback((e) => {
    if (e.target.checked) {
      setCheckList(selectOptions);
    } else {
      setCheckList([]);
    }
    setCheckAll(e.target.checked);
  }, []);

  const handleTime = useCallback(
    (e) => {
      setStartDate(Date.now() - e.target.value);
    },
    [startDate]
  );

  const handleSearch = useCallback((e) => {
    setSearch(e);
  }, []);

  const parts = useMemo(() => logsdata?.split(regex) || [], [logsdata]);
  const updatedContent = useMemo(() => {
    return parts.map((part, index) => {
      if (index < parts.length - 1) {
        if (regex.test(part)) {
          return (
            <React.Fragment key={index}>
              {part.split(regex).map((subpart, subindex) => {
                if (regex.test(subpart)) {
                  let style = {};
                  if (subpart === 'INFO') {
                    style = { color: 'green' };
                  } else if (subpart === 'ERROR') {
                    style = { color: 'red' };
                  } else if (subpart === 'WARN') {
                    style = { color: 'orange' };
                  }
                  return (
                    <React.Fragment key={subindex}>
                      <span style={style}>{subpart}</span>
                    </React.Fragment>
                  );
                }
                return subpart;
              })}
            </React.Fragment>
          );
        }
      }
      return part;
    });
  }, [parts, regex]);

  const downloadLogFile = () => {
    const element = document.createElement('a');
    const file = new Blob([logsdata, { type: 'text/plain' }]);
    element.href = URL.createObjectURL(file);
    element.download = 'system.log';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    URL.revokeObjectURL(element.href);
  };

  return (
    <div className="system-logs">
      <div className="system-header">
        <p className="page-title">System Logs</p>
        <div className="right">
          <div className="search">
            <Search
              placeholder="Search"
              onSearch={handleSearch}
              // onChange={(e) => setSearch(e.target.value)}
              enterButton
              // value={search}
            />
          </div>
          <div className="actions">
            <Button className="primary-btn" onClick={downloadLogFile}>
              Download
            </Button>
          </div>
        </div>
      </div>
      <div className="filters">
        <RangePicker onChange={onDateChange} />
        <Radio.Group buttonStyle="solid" onChange={handleTime}>
          <Radio.Button value="600000">10 min</Radio.Button>
          <Radio.Button value="3600000">1 hr</Radio.Button>
          <Radio.Button value="21600000">6 hr</Radio.Button>
          <Radio.Button value="86400000">1 day</Radio.Button>
        </Radio.Group>
      </div>
      <div className="checkboxes">
        <Checkbox onChange={onCheckAllChange} checked={checkAll}>
          All
        </Checkbox>
        <Checkbox.Group
          options={selectOptions}
          defaultValue={[selectOptions[0]]}
          onChange={onCheckBoxChange}
          value={checkList}
        />
      </div>
      <pre
        //The height needs to be adjusted to ensure that
        //the scrolling mechanism functions properly,
        //i.e., it scrolls to the bottom every time new content is added.
        ref={preRef}
      >
        {updatedContent}
      </pre>
      <Pagination
        onChange={onPageChange}
        onShowSizeChange={onPageChange}
        size="small"
        total={1000}
        showSizeChanger
        showQuickJumper
        defaultPageSize={limit}
        pageSizeOptions={['50', '100', '150', '200']}
      />
    </div>
  );
};

export default Main;
