import './styles.scss';
import ResetPasswordForm from '../../containers/authentication/resetPasswordForm/index';

/**
 * @description Login
 * @type Page
 * @author
 */
const Main = () => {
  return (
    <div>
      <ResetPasswordForm />
    </div>
  );
};

Main.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};

Main.displayName = 'Reset-Password';
export default Main;
