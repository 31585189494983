import React, { useCallback, useEffect, useState } from 'react';
import { ModalTrigger } from '@arivaa-react/components/modal';
import Form from '@arivaa-react/components/form';
import createSmartInput from '@arivaa-react/components/createSmartInput';
import { EyeOutlined } from '@ant-design/icons';
import { url } from '../../../constants/api';
import { useApiWithSideEffects } from '../../../hooks/api';
const spinnerSelector = '.home-layout';
const Main = createSmartInput(
  React.forwardRef((props, ref) => {
    const {
      value: initialValue,
      modalProps = {},
      modalTriggerProps = {},
      onChange,
    } = props;
    let { children } = props;
    const [value, setValue] = useState(initialValue);
    const [authenticated, setAuthenticated] = useState(false);
    const { callApi } = useApiWithSideEffects({
      errorMessage: 'Authentication Failed',
      successMessage: null,
      spinnerSelector,
    });
    const handleSubmit = useCallback(
      async (data) => {
        const res = await callApi(url('configuration/decrypt'), {
          method: 'POST',
          body: data,
        });
        setValue(res);
        setAuthenticated(true);
        onChange instanceof Function && onChange(res);
      },
      [value, props]
    );
    useEffect(() => {
      setValue(initialValue);
    }, [props.value]);

    /* For encrypting the value after update*/
    useEffect(() => {
      setAuthenticated(false);
    }, [props.updatedAt]);

    if (children instanceof Function) {
      children = children({
        ...props,
      });
    }

    children = children || <span>{value}</span>;
    return (
      <>
        {authenticated ? (
          children
        ) : (
          <ModalTrigger
            modalProps={{
              title: 'Authorize',
              footer: null,
              ...modalProps,
            }}
            content={
              <Form
                elements={[
                  {
                    type: 'password',
                    name: 'password',
                    label: 'Password',
                    required: true,
                  },
                ]}
                onSubmit={handleSubmit}
                submitButtonProps={{
                  children: 'Submit',
                }}
              />
            }
            {...modalTriggerProps}
          >
            <EyeOutlined />
          </ModalTrigger>
        )}
      </>
    );
  })
);

export default Main;
