import { useEffect, useRef, useCallback } from 'react';
import { message } from 'antd';
import Link from '@arivaa-react/components/link';
import AuthFormWrapper from '../../authFormWrapper/index';
import { useLocation } from 'react-router-dom';
import { goToLogin } from '../defaultLoginLink';
import { ApiForm } from '../../apiForm';
import { useSecureApi } from '../../../hooks/api';
import { url } from '../../../constants/api';
import { isDemoEnabled } from '../../../hooks/useAppConfig';
import { translate } from '../../../localization';

/**
 * @description Reset Password Form
 * @type Container
 * @author Inderdeep
 */
const Main = () => {
  const form = useRef(null);
  const location = useLocation();
  const { search } = location || {};
  const token = new URLSearchParams(search).get('token');
  const type = new URLSearchParams(search).get('type');
  const key = new URLSearchParams(search).get('key');
  const apiUrl = url(
    type === 'phone' ? 'password-reset-phone' : 'password-reset'
  );
  const { callApi } = useSecureApi();
  const checkValidity = useCallback(async () => {
    try {
      await callApi(`${apiUrl}/${key}`);
    } catch (e) {
      message.error(translate('resetPassword.error.invalidLink'));
      goToLogin();
    }
  }, [key, apiUrl]);

  const onSuccess = useCallback(() => {
    form.current.resetFields();
    message.success(translate('resetPassword.success'));
    goToLogin();
  }, []);
  useEffect(() => {
    checkValidity();
  }, []);
  return (
    <AuthFormWrapper title="Reset Password">
      <ApiForm
        ref={form}
        elements={[
          {
            type: 'password',
            name: 'password',
            label: 'Password',
            required: true,
            options: {
              initialValue: isDemoEnabled ? '123456' : undefined,
            },
          },
          {
            type: 'password',
            name: 'confirmPassword',
            label: 'Confirm Password',
            required: true,
            requiredMessage: 'Please confirm the password',
            options: {
              initialValue: isDemoEnabled ? '123456' : undefined,
              rules: [
                {
                  validator: (rule, value, callback) => {
                    const { getFieldValue } = form.current;
                    if (value !== '' && value !== getFieldValue('password')) {
                      callback(translate('common.error.password.match'));
                    } else {
                      callback();
                    }
                  },
                  name: 'confirmPassword',
                },
              ],
            },
          },
        ]}
        onSuccess={onSuccess}
        submitButtonProps={{
          children: 'Change Password',
        }}
        errorMessage={translate('resetPassword.error.server')}
        excludedFields={['confirmPassword']}
        extra={{ token, key }}
        apiUrl={`${apiUrl}/confirm`}
      />
      <p className="option">
        Clicked by mistake?{' '}
        <Link routeKey={`login`} routeParams={{ type }}>
          Login
        </Link>
      </p>
    </AuthFormWrapper>
  );
};

Main.displayName = 'Reset-Password-Form';

export default Main;
