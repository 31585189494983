import { url } from '../../../constants/api';
import DecryptAuth from '../../containers/dcrypt-auth';
import { SyncOutlined } from '@ant-design/icons';
import { Crud } from '../../containers/crud/crud';
import ConfigurationForm from './form';
import { translate } from '../../../localization';
import { Button } from 'antd';
import {
  applyPromiseToasterEffects,
  useApi,
  useSecureApi,
} from '../../../hooks/api';

export default function Configuration() {
  const refreshConfigApi = useApi();
  const refreshIndexesApi = useSecureApi();
  const refreshCacheApi = useSecureApi();
  return (
    <Crud api="configuration" FormComponent={ConfigurationForm}>
      <Crud.Header title="Configuration">
        <Crud.Header.Actions.Common>
          <Button
            type="primary"
            loading={refreshConfigApi.loading}
            onClick={() =>
              applyPromiseToasterEffects(
                refreshConfigApi.callApi('refresh-config'),
                {
                  successMessage:
                    'Refreshing Config initiated. Might take about a minute',
                }
              )
            }
            icon={<SyncOutlined spin={refreshIndexesApi.loading} />}
          >
            Refresh Config
          </Button>
          <Button
            type="primary"
            loading={refreshIndexesApi.loading}
            onClick={() =>
              applyPromiseToasterEffects(
                refreshIndexesApi.callApi(url(`initialize-indexes`)),
                {
                  successMessage:
                    'Refreshing indexes initiated. Might take about a minute',
                }
              )
            }
            icon={<SyncOutlined spin={refreshIndexesApi.loading} />}
          >
            Refresh Indexes
          </Button>
          <Button
            type="primary"
            loading={refreshCacheApi.loading}
            onClick={() =>
              applyPromiseToasterEffects(
                refreshCacheApi.callApi(url(`initialize-cache`)),
                {
                  successMessage:
                    'Refreshing cache initiated. Might take about a minute',
                }
              )
            }
            icon={<SyncOutlined spin={refreshCacheApi.loading} />}
          >
            Refresh Cache
          </Button>
        </Crud.Header.Actions.Common>
      </Crud.Header>

      <Crud.Table
        actions={[
          ...Crud.Table.RowActions.Common,
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'text',
                  label: 'Enter new key',
                  name: 'key',
                  inputProps: {
                    placeholder: 'Key',
                  },
                },
              ]}
            />
          ),
        ]}
        columns={[
          {
            dataIndex: 'key',
            title: translate('configuration.key.label'),
          },
          {
            title: translate('configuration.type.label'),
            dataIndex: 'type',
          },
          {
            title: translate('configuration.value.label'),
            dataIndex: 'value',
            render: (value, { key, type, encrypted, updatedAt }) => {
              if (value === null || typeof value === 'undefined') {
                value = '';
              }
              return type === 'file' ? (
                <div>
                  <div className="image">
                    <img
                      src={value}
                      style={{
                        width: 200,
                        marginBottom: 10,
                      }}
                    />
                  </div>
                  <a target="_blank" href={value} rel="noreferrer">
                    Download
                  </a>
                </div>
              ) : encrypted ? (
                <DecryptAuth value={value.toString()} updatedAt={updatedAt} />
              ) : (
                <div>{value.toString()}</div>
              );
            },
          },
          {
            title: translate('configuration.description.label'),
            dataIndex: 'description',
          },
        ]}
      >
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                type: 'text',
                name: 'key',
                inputProps: {
                  placeholder: 'Key',
                },
              },
            ]}
          />
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
        <Crud.Table.TableActions.Common />
      </Crud.Table>
    </Crud>
  );
}
