import React, { useCallback } from 'react';
import './styles.scss';
import BlockEditor from '@arivaa-react/components/blockEditor';
import createSmartInput from '@arivaa-react/components/createSmartInput';
import { useSecureApi } from '../../../hooks/api';
import { url } from '../../../constants/api';
import { useTheme } from '../../../hooks/useTheme';
/**
 * @description Block Editor Component
 * @type Component
 * @author Inderdeep
 */
const Main = createSmartInput(
  React.forwardRef(
    (props, ref) => {
      const { callApi } = useSecureApi();
      const { themeString } = useTheme();
      const uploadImage = useCallback(async (blobInfo, progress) => {
        const blob = blobInfo.blob();
        const data = await callApi(url`storage`, {
          method: 'POST',
          body: {
            fileCode: 'ckEditorFile',
            file: blob,
            type: blob.type,
            name: blob.name,
            createFormData: true,
          },
        });
        return data?.url;
      }, []);
      return (
        <BlockEditor
          {...props}
          editorProps={{
            ...props?.editorProps,
            init: {
              images_upload_handler: uploadImage,
              content_style: `:root {${themeString}}`,
              ...props?.editorProps?.init,
              skin: 'oxide',
              content_css: 'default',
            },
          }}
        />
      );
    },
    {
      defaultValue: '',
    }
  )
);

export default Main;
