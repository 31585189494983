import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

export function Chart({ data }) {
  return (
    <>
      {data?.digital && (
        <HighchartsReact
          highcharts={Highcharts}
          options={createChart1(data?.digital)}
        />
      )}
      {data?.analog && (
        <HighchartsReact
          highcharts={Highcharts}
          options={createChart2(data?.analog)}
        />
      )}
    </>
  );
}

function createChart1(data) {
  const { variables, series } = createChartData(data);

  return {
    chart: {
      type: 'scatter',
      height: 400,
      zooming: {
        type: 'xy',
      },
    },
    title: {
      text: data?.cmi?.description,
    },
    tooltip: {
      headerFormat: '{series.name}<br>',
      pointFormat: '{point.x:%e %b %H:%M:%S}: <b>{point.y > 0 ? 1 : 0}</b>',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Date & Time',
      },
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: '',
      },
      tickInterval: 1 / variables.length,
      min: 0,
      max: 1,
    },
    legend: {
      enabled: true,
      title: {
        text: 'Click on variable name to toggle data on and off',
      },
    },
    zooming: {},
    credits: {
      enabled: false,
    },
    colors: variables.map(({ key }) => data.colour[key]),
    series: Object.values(series).map((item, index) => {
      item.data = item.data.map((arr) => {
        return [
          arr[0],
          arr[1] === 1 ? arr[1] - index * (1 / variables.length) : null,
        ];
      });
      item.marker = {
        symbol: 'circle',
      };
      return item;
    }),
  };
}

function createChart2(data) {
  const { variables, series } = createChartData(data);
  return {
    chart: {
      type: 'line',
      height: 900,
      zooming: {
        type: 'xy',
      },
    },
    title: {
      text: data?.cmi?.description,
    },
    tooltip: {
      headerFormat: '{series.name}<br>',
      pointFormat: '{point.x:%e %b %H:%M:%S}: <b>{point.y:.2f}</b>',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Date & Time',
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      tickInterval: 2,
    },
    legend: {
      enabled: true,
      title: {
        text: 'Click on variable name to toggle data on and off',
      },
    },
    zooming: {},
    credits: {
      enabled: false,
    },
    colors: variables.map(({ key }) => data.colour[key]),
    series: Object.values(series),
  };
}

function createChartData(data) {
  const variables = Object.keys(data.description).map((key) => {
    return {
      key,
      label: data.description[key],
    };
  });
  const series = {};
  data.val.forEach((item) => {
    const timestamp = moment(item.zeit, 'YYYY-MM-DD HH:mm:ss').valueOf();
    variables.forEach(({ key, label }) => {
      series[key] = series[key] || {
        name: label,
        data: [],
      };
      series[key].data.push([timestamp, item[key] * (data.factor?.[key] || 1)]);
    });
  });
  return { variables, series };
}
